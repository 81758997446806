<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('leave_of_absence_settings_title')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('leave_of_absence_settings_title')"
                              :isFilter="false"/>
            </template>
            <div class="row">
                <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
                    <ValidationObserver ref="leaveForm">
                        <div class="row">
                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="academicYear" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('academic_year')">
                                        <academic-years-selectbox
                                            :validate-error="errors[0]"
                                            v-model="leaveForm.academicYear">
                                        </academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="period" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('periods')">
                                        <b-form-checkbox-group
                                            v-model="leaveForm.period"
                                            :options="periodOptions"
                                            value-field="item"
                                            text-field="name"
                                        ></b-form-checkbox-group>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="startDate" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('start_date')" class="position-relative">
                                        <select-date :is-time="true" v-model="leaveForm.startDate" :validation-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                <ValidationProvider name="endDate" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('end_date')" class="position-relative">
                                        <select-date :is-time="true" v-model="leaveForm.endDate" :validation-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-12 col-xl-12 col-xxl-12">
                                <div class="row">
                                    <div class="col-sm-6 col-xs-12 mb-5">
                                        <b-button @click="sendLeaveSetting"
                                                  type="button"
                                                  variant="primary"
                                                  class="btn-lg d-flex justify-content-between align-items-center">
                                            {{ $t('save') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ValidationObserver>
                </div>
            </div>

        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";

// Services
import SettingsService from "@/services/SettingsService";

// Other
import {ValidationObserver, ValidationProvider} from "vee-validate";
import moment from "moment";
import SelectDate from "@/components/interactive-fields/SelectDate";
import TimePicker from "@/components/elements/TimePicker.vue";

export default {
    components: {
        SelectDate,
        AppLayout,
        Header,
        HeaderMobile,
        AcademicYearsSelectbox,
        ValidationProvider,
        ValidationObserver,
        TimePicker
    },
    data() {
        return {
            leaveForm: {
                academicYear: null,
                period: [],
                startDate: null,
                endDate: null
            },

            periodOptions: [
                {name: this.$t('fall'), item: '1'},
                {name: this.$t('spring'), item: '2'}
            ],

            masks: {
                input: "DD-MM-YYYY",
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("leave_of_absence_settings_title")
        }
    },
    methods: {
        async sendLeaveSetting() {
            if(this.checkPermission('settings_leaveofabsence_store')){
                const isValid = await this.$refs.leaveForm.validate();
                if (isValid) {
                    let formData = {
                        academic_year: this.leaveForm.academicYear,
                        start_date: moment(this.leaveForm.startDate).format('YYYY-MM-DD HH:mm'),
                        end_date: moment(this.leaveForm.endDate).format('YYYY-MM-DD HH:mm'),
                        fall: this.leaveForm.period.indexOf("1") > -1 ? 1 : 0,
                        spring: this.leaveForm.period.indexOf("2") > -1 ? 1 : 0
                    }

                    SettingsService.storeLeaveSettingForm(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$refs.leaveForm.errors.academicYear.push(this.$t('api.' + e.data.message));
                            }
                        } else {
                            if (e.data.errors.academic_year) {
                                this.$refs.leaveForm.errors.academicYear.push(e.data.errors.academic_year[0]);
                            }
                            if (e.data.errors.fall) {
                                this.$refs.leaveForm.errors.period.push(e.data.errors.fall[0]);
                            }
                            if (e.data.errors.spring) {
                                this.$refs.leaveForm.errors.period.push(e.data.errors.spring[0]);
                            }
                            if (e.data.errors.start_date) {
                                this.$refs.leaveForm.errors.startDate.push(e.data.errors.start_date[0]);
                            }
                            if (e.data.errors.end_date) {
                                this.$refs.leaveForm.errors.endDate.push(e.data.errors.end_date[0]);
                            }
                        }
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        getLeaveSettings() {
            SettingsService.getLeaveSettingForm()
                .then(response => {
                    let data = response.data.data
                    this.leaveForm.academicYear = data['leave_of_absence.academic_year'][0].value
                    this.leaveForm.period = [];
                    if (data['leave_of_absence.fall'][0].value == "1") {
                        this.leaveForm.period.push("1");
                    }
                    if (data['leave_of_absence.spring'][0].value == "1") {
                        this.leaveForm.period.push("2");
                    }
                    this.leaveForm.startDate = moment(data['leave_of_absence.start_date'][0].value).format()
                    this.leaveForm.endDate = moment(data['leave_of_absence.end_date'][0].value).format()
                })
        }
    },
    created() {
        this.getLeaveSettings()
    }
}
</script>
